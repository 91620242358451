import Home from '~/pages/Home';

export const meta = () => {
  return [
    {
      title:
        "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
    },
  ];
};

export default Home;
