const AboutQuizzly = () => {
  return (
    <div className="bg-gradient-light-blue container-center">
      <div className="main-container container-center about-quizzly">
        <h5 className="about-quizzly-title">Unleash the Power of Captivating Engagement:</h5>
        <p className="about-quizzly-description">
          Quizzly.ai introduces a game-changing AI-powered quiz widget designed to captivate your audience like never
          before. Our revolutionary technology delves deep into the heart of your content, crafting interactive quizzes
          that instantly grab users&apos; attention and keep them hooked. Say goodbye to mundane interactions and hello
          to huge engagement rates that will redefine your website&apos;s success.
        </p>
      </div>
    </div>
  );
};

export default AboutQuizzly;
