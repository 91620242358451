import React, { Suspense, useState } from 'react';
import Header from '~/components/Header';
import AboutQuizzly from '~/components/AboutQuizzly';
import Testimonials from '~/components/Testimonials';
import ReadyToInstall from '~/components/ReadyToInstall';

import 'lazysizes';

import '../styles/index.scss';

// const Header = React.lazy(() => import('../components/Header'));
// const AboutQuizzly = React.lazy(() => import('../components/AboutQuizzly'));
// const Testimonials = React.lazy(() => import('../components/Testimonials'));
const OurClients = React.lazy(() => import('../components/OurClients'));
const DetailedInfo = React.lazy(() => import('../components/DetailedInfo'));
const WhyQuizzly = React.lazy(() => import('../components/WhyQuizzly'));
// const Advantages = React.lazy(() => import('../components/Advantages'));
// const ReadyToInstall = React.lazy(() => import('../components/ReadyToInstall'));
const Footer = React.lazy(() => import('../components/Footer'));
const Modal = React.lazy(() => import('../components/Modal'));

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <Suspense>
        <Header setModalIsOpen={setModalIsOpen} />
        <AboutQuizzly />
        <Testimonials />
        <OurClients />
        <DetailedInfo />
        <WhyQuizzly />
        {/*<Advantages />*/}
        <ReadyToInstall setModalIsOpen={setModalIsOpen} />
        <Footer setModalIsOpen={setModalIsOpen} />
        <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      </Suspense>
      <script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        data-domain-script="c9f19c12-39ba-450a-b87a-fad3537b0b50"
        defer
      ></script>
    </>
  );
};

export default Home;
