export const Quotes = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
    <path
      d="M21.9177 30.8574C21.9177 29.1341 21.4673 27.6888 20.5665 26.5158C19.928 25.709 19.0625 25.1782 17.9677 24.9256C16.8907 24.675 15.8723 24.6573 14.9519 24.8708C14.6386 23.0104 15.1477 21.0403 16.4402 18.9527C17.7327 16.8671 19.4071 15.2965 21.4497 14.2468L18.3535 9.79163C16.7869 10.5671 15.2985 11.5502 13.9277 12.7389C12.5373 13.9276 11.3035 15.2945 10.2069 16.8397C9.1102 18.3848 8.2877 20.1297 7.75895 22.1076C7.2302 24.0855 7.08333 26.1026 7.33595 28.1784C7.66495 30.9201 8.55012 33.1134 9.99145 34.7388C11.4308 36.3858 13.2266 37.2083 15.373 37.2083C17.2627 37.2083 18.8314 36.6404 20.073 35.4889C21.3028 34.3609 21.9138 32.8158 21.9138 30.8515L21.9177 30.8574ZM39.7855 30.8574C39.7855 29.1341 39.3351 27.6888 38.4343 26.5158C37.7959 25.6933 36.9264 25.1606 35.8356 24.9158C34.7389 24.673 33.7343 24.6613 32.8197 24.8728C32.5064 23.0319 32.996 21.054 34.2924 18.9586C35.5849 16.8828 37.2554 15.3161 39.2979 14.2586L36.2096 9.79163C34.643 10.5671 33.1644 11.5502 31.7838 12.7389C30.3807 13.9452 29.1346 15.3229 28.0747 16.8397C26.9859 18.3868 26.1751 20.1297 25.6464 22.1076C25.1114 24.0843 24.967 26.1464 25.2214 28.1784C25.5465 30.9201 26.4277 33.1134 27.8652 34.7388C29.3006 36.3721 31.0905 37.1907 33.2369 37.1907C35.1306 37.1907 36.6992 36.6228 37.9408 35.4732C39.1687 34.3452 39.7855 32.8001 39.7855 30.8359V30.8574Z"
      fill="black"
    />
    <path
      d="M21.9177 30.8574C21.9177 29.1341 21.4673 27.6888 20.5665 26.5158C19.928 25.709 19.0625 25.1782 17.9677 24.9256C16.8907 24.675 15.8723 24.6573 14.9519 24.8708C14.6386 23.0104 15.1477 21.0403 16.4402 18.9527C17.7327 16.8671 19.4071 15.2965 21.4497 14.2468L18.3535 9.79163C16.7869 10.5671 15.2985 11.5502 13.9277 12.7389C12.5373 13.9276 11.3035 15.2945 10.2069 16.8397C9.1102 18.3848 8.2877 20.1297 7.75895 22.1076C7.2302 24.0855 7.08333 26.1026 7.33595 28.1784C7.66495 30.9201 8.55012 33.1134 9.99145 34.7388C11.4308 36.3858 13.2266 37.2083 15.373 37.2083C17.2627 37.2083 18.8314 36.6404 20.073 35.4889C21.3028 34.3609 21.9138 32.8158 21.9138 30.8515L21.9177 30.8574ZM39.7855 30.8574C39.7855 29.1341 39.3351 27.6888 38.4343 26.5158C37.7959 25.6933 36.9264 25.1606 35.8356 24.9158C34.7389 24.673 33.7343 24.6613 32.8197 24.8728C32.5064 23.0319 32.996 21.054 34.2924 18.9586C35.5849 16.8828 37.2554 15.3161 39.2979 14.2586L36.2096 9.79163C34.643 10.5671 33.1644 11.5502 31.7838 12.7389C30.3807 13.9452 29.1346 15.3229 28.0747 16.8397C26.9859 18.3868 26.1751 20.1297 25.6464 22.1076C25.1114 24.0843 24.967 26.1464 25.2214 28.1784C25.5465 30.9201 26.4277 33.1134 27.8652 34.7388C29.3006 36.3721 31.0905 37.1907 33.2369 37.1907C35.1306 37.1907 36.6992 36.6228 37.9408 35.4732C39.1687 34.3452 39.7855 32.8001 39.7855 30.8359V30.8574Z"
      fill="url(#paint0_linear_50_133)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_50_133"
        x1="10.8448"
        y1="15.275"
        x2="37.245"
        y2="19.7276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#43E9F7" />
        <stop offset="1" stopColor="#7081DB" />
      </linearGradient>
    </defs>
  </svg>
)
