import React, { useEffect, useState } from 'react';
import { Quotes } from './Quotes';
import euronews from '/static/images/clients/euronews.svg?url';
import domainOptions from '/static/images/clients/domain_options.webp?url';
import phoblographer from '/static/images/clients/thephoblographer.webp?url';
import timesOfSanDiego from '/static/images/clients/times-of-san-diego.webp?url';

import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';

const testimonials = [
  {
    name: 'Tom Roche',
    role: 'VP Digital Commercial Strategy at Euronews',
    avatar: euronews,
    feedback:
      'Vuukle has been a successful partner due to their engaging technology and compelling business model. There is always a willingness to learn and improve, which is critical in the existing landscape. We look forward top development the partnership.',
  },
  {
    name: 'Sam Kerzhnerman',
    role: 'General Manager at DomainOptions.net, Inc.',
    avatar: domainOptions,
    feedback:
      'We started using Vuukle in 2021 and they increased our ad revenue around 2x times in comparison with the ad platform we used before. I like how Vuukle handles all of your ad operations on their end and you only need to install their code on the website.',
  },
  {
    name: 'Chris Gampat',
    role: 'Editor in Chief/Publisher at Photography Publication',
    avatar: phoblographer,
    feedback: `Vuukle has helped give Minority Owned Small Businesses like us an easier solution to manage various tools and monetization. They're also very easy to talk to and truly care about helping to build their customers.`,
  },
  {
    name: 'Chris Jennewein',
    role: 'Editor & Publisher at Times of San Diego',
    avatar: timesOfSanDiego,
    feedback: `Vuukle has been a key partner for our local news website for the past four years. Their commenting application is world class, and their advertising support has proven crucial to business sustainability. They're also always immediately responsive if we have questions or problems.`,
  },
];

const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      renderMode: 'performance',
      slides: {
        perView: 3,
      },
      breakpoints: {
        '(max-width: 480px)': {
          slides: {
            perView: 1,
          },
        },
        '(max-width: 786px)': {
          slides: {
            perView: 2,
          },
        },
        '(min-width: 1200px)': {
          slides: {
            perView: 3,
          },
        },
      },
      created() {
        setLoaded(true);
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
    },
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      instanceRef.current?.next();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section className="bg-white container-center">
      <div className="main-container container-center">
        <p className="testimonials-title">Testimonials</p>
      </div>

      <div ref={sliderRef} className="main-container mb-80">
        <div className="keen-slider">
          {testimonials.map((i) => (
            <div className="keen-slider__slide" key={i.name}>
              <div className="feedback-container">
                <img
                  src={i.avatar}
                  alt={i.role}
                  className={`feedback-logo${i.name === 'Tom Roche' ? ' euronews-logo' : ''}`}
                />
                <Quotes />
                <div className="feedback-txt-container">
                  <p className="feedback-item">{i.feedback}</p>
                  <div className="feedback-author-container">
                    <p className="feedback-author">{i.name}</p>
                    <p className="feedback-author-role">{i.role}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {loaded && instanceRef.current && (
          <div className="slider-dots">
            {[...Array(instanceRef.current.track.details.slides.length).keys()].map((idx) => {
              return (
                <button
                  aria-label={`Move to slide ${idx + 1}`}
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={'slider-dot' + (currentSlide === idx ? ' slider-dot-active' : '')}
                ></button>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default Testimonials;
